import { SUBSCRIPTION_TYPE, LocalisedPricing, UPGRADE_PLAN_TRIGGER_TYPE } from '@pixcap/ui-core/models/store/user.interface';
import { COUNTRY_CODES } from '@pixcap/ui-core/constants/user.constants';

// Do not call this function except from user getter getPricingForUser.
// getPricingForUser getter should be single gatekeeper for pricing.
export function getPricingByUserCountry(countryCode: string): LocalisedPricing {
	switch (countryCode) {
		// EURO
		case COUNTRY_CODES.ANDORRA:
		case COUNTRY_CODES.AUSTRIA:
		case COUNTRY_CODES.BELGIUM:
		case COUNTRY_CODES.CYPRUS:
		case COUNTRY_CODES.ESTONIA:
		case COUNTRY_CODES.FINLAND:
		case COUNTRY_CODES.FRANCE:
		case COUNTRY_CODES.GERMANY:
		case COUNTRY_CODES.GREECE:
		case COUNTRY_CODES.ITALY:
		case COUNTRY_CODES.LATVIA:
		case COUNTRY_CODES.LICHTENSTEIN:
		case COUNTRY_CODES.LUXEMBOURG:
		case COUNTRY_CODES.MALTA:
		case COUNTRY_CODES.MONACO:
		case COUNTRY_CODES.NETHERLANDS:
		case COUNTRY_CODES.PORTUGAL:
		case COUNTRY_CODES.SLOVAKIA:
		case COUNTRY_CODES.SLOVENIA:
		case COUNTRY_CODES.SPAIN:
			return {
				CURRENCY_PREFIX: '€',
				CURRENCY_CODE: 'EUR',
				PRO_MONTH: 14,
				PRO_ANNUAL: 111,
				ELITE_MONTH: 28,
				ELITE_ANNUAL: 224,
				VIDEO_LIMITS: [
					{ QUANTITY: 10, PRICE: 9 },
					{ QUANTITY: 20, PRICE: 18 },
					{ QUANTITY: 40, PRICE: 30 },
				],
			};
		// POUND
		case COUNTRY_CODES.UNITED_KINGDOM:
			return {
				CURRENCY_PREFIX: '£',
				CURRENCY_CODE: 'GBP',
				PRO_MONTH: 12,
				PRO_ANNUAL: 95,
				ELITE_MONTH: 24,
				ELITE_ANNUAL: 190,
				VIDEO_LIMITS: [
					{ QUANTITY: 10, PRICE: 7 },
					{ QUANTITY: 20, PRICE: 15 },
					{ QUANTITY: 40, PRICE: 26 },
				],
			};
		// Brazilian Real
		case COUNTRY_CODES.BRAZIL:
			return {
				CURRENCY_PREFIX: 'R$',
				CURRENCY_CODE: 'BRL',
				PRO_MONTH: 85,
				PRO_ANNUAL: 681,
				ELITE_MONTH: 170,
				ELITE_ANNUAL: 1362,
				VIDEO_LIMITS: [
					{ QUANTITY: 10, PRICE: 56 },
					{ QUANTITY: 20, PRICE: 112 },
					{ QUANTITY: 40, PRICE: 190 },
				],
			};
		case COUNTRY_CODES.AUSTRALIA:
			return {
				CURRENCY_PREFIX: 'A$',
				CURRENCY_CODE: 'AUD',
				PRO_MONTH: 22,
				PRO_ANNUAL: 180,
				ELITE_MONTH: 45,
				ELITE_ANNUAL: 360,
				VIDEO_LIMITS: [
					{ QUANTITY: 10, PRICE: 14 },
					{ QUANTITY: 20, PRICE: 29 },
					{ QUANTITY: 40, PRICE: 50 },
				],
			};
		case COUNTRY_CODES.CANADA:
			return {
				CURRENCY_PREFIX: 'CA$',
				CURRENCY_CODE: 'CAD',
				PRO_MONTH: 20,
				PRO_ANNUAL: 165,
				ELITE_MONTH: 40,
				ELITE_ANNUAL: 330,
				VIDEO_LIMITS: [
					{ QUANTITY: 10, PRICE: 13 },
					{ QUANTITY: 20, PRICE: 27 },
					{ QUANTITY: 40, PRICE: 46 },
				],
			};
		case COUNTRY_CODES.SINGAPORE:
			return {
				CURRENCY_PREFIX: 'SGD',
				CURRENCY_CODE: 'SGD',
				PRO_MONTH: 20,
				PRO_ANNUAL: 163,
				ELITE_MONTH: 40,
				ELITE_ANNUAL: 324,
				VIDEO_LIMITS: [
					{ QUANTITY: 10, PRICE: 13 },
					{ QUANTITY: 20, PRICE: 26 },
					{ QUANTITY: 40, PRICE: 44 },
				],
			};
		case COUNTRY_CODES.INDIA:
			return {
				CURRENCY_PREFIX: '₹',
				CURRENCY_CODE: 'INR',
				PRO_MONTH: 1250,
				PRO_ANNUAL: 10000,
				ELITE_MONTH: 2500,
				ELITE_ANNUAL: 20000,
				VIDEO_LIMITS: [
					{ QUANTITY: 10, PRICE: 839 },
					{ QUANTITY: 20, PRICE: 1678 },
					{ QUANTITY: 40, PRICE: 2853 },
				],
			};
		case COUNTRY_CODES.TURKEY:
			return {
				CURRENCY_PREFIX: '₺',
				CURRENCY_CODE: 'TRY',
				PRO_MONTH: 488,
				PRO_ANNUAL: 3909,
				ELITE_MONTH: 977,
				ELITE_ANNUAL: 7818,
				VIDEO_LIMITS: [
					{ QUANTITY: 10, PRICE: 339 },
					{ QUANTITY: 20, PRICE: 679 },
					{ QUANTITY: 40, PRICE: 1154 },
				],
			};
		default:
			return {
				CURRENCY_PREFIX: '$',
				CURRENCY_CODE: 'USD',
				PRO_MONTH: 15,
				PRO_ANNUAL: 120,
				ELITE_MONTH: 30,
				ELITE_ANNUAL: 240,
				VIDEO_LIMITS: [
					{ QUANTITY: 10, PRICE: 10 },
					{ QUANTITY: 20, PRICE: 20 },
					{ QUANTITY: 40, PRICE: 34 },
				],
			};
	}
}

export const STRIPE_UNSUPPORTED_COUNTRIES = [
	COUNTRY_CODES.BRAZIL,
	COUNTRY_CODES.INDIA,
	COUNTRY_CODES.INDONESIA,
	COUNTRY_CODES.PAKISTAN,
	COUNTRY_CODES.PHILIPPINES,
	COUNTRY_CODES.TURKEY,
];

export const FREE_TRIAL_PLAN_INFO = {
	name: 'FREE Trial Plan.',
	price: 'FREE TRIAL',
	period: null,
	showChangePlan: true,
	showIcon: false,
	message: 'Get more, create more!',
};

export const CREDITS_REQUIRED_PER_SECOND_FOR_VIDEO_EXPORT = 10;

export const USAGE_LIMIT = {
	// Per day
	DEFAULT_DOWNLOAD: {
		FREE: 5,
		PRO: null,
		ELITE: null,
	},
	// Per month
	HQ_IMAGE_DOWNLOAD: {
		FREE: 5,
		PRO: 50,
		ELITE: null,
	},
	// Per month
	HQ_VIDEO_DOWNLOAD: {
		FREE: 0,
		PRO: 10,
		ELITE: 30,
	},
	// Per month
	AI_USAGE: {
		FREE: 20,
		PRO: 500,
		ELITE: null,
	},
	// Per day
	EXPORT_3D: {
		FREE: 0,
		PRO: 0,
		ELITE: 10,
	},
};

export const UPGRADE_FEATURE_SERVICES: {
	[subscriptionType: string]: {
		description: string;
		featureType: UPGRADE_PLAN_TRIGGER_TYPE | UPGRADE_PLAN_TRIGGER_TYPE[] | null;
		notIncluded?: boolean;
		hiddenByDefault?: boolean;
	}[];
} = {
	[SUBSCRIPTION_TYPE.PROFESSIONAL]: [
		{
			description: `Access to all PREMIUM designs`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.ACCESS_PREMIUM_PROJECT,
		},
		{
			description: `${USAGE_LIMIT.DEFAULT_DOWNLOAD.PRO === null ? 'Unlimited' : USAGE_LIMIT.DEFAULT_DOWNLOAD.PRO} default-quality downloads`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_DEFAULT_DOWNLOAD,
			hiddenByDefault: true,
		},
		{
			description: `${USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.PRO === null ? 'Unlimited' : USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.PRO} High-quality images per month`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_HQ_IMAGE_DOWNLOAD,
		},
		{
			description: `${USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.PRO === null ? 'Unlimited' : USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.PRO} High-quality videos per month`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_HQ_VIDEO_DOWNLOAD,
		},
		{
			description: `${USAGE_LIMIT.AI_USAGE.PRO === null ? 'Unlimited' : USAGE_LIMIT.AI_USAGE.PRO} AI generations per month`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.AI_STYLIST_EXCEED_LIMIT,
			hiddenByDefault: true,
		},
		{
			description: `Exclusive presets & materials`,
			featureType: null,
		},
		{
			description: `Exclusive character poses`,
			featureType: null,
		},
		{
			description: `Commercial license`,
			featureType: null,
		},
		{
			description: `3D file exports`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.GLB_DOWNLOAD,
			notIncluded: true,
		},
	],
	[SUBSCRIPTION_TYPE.ELITE]: [
		{
			description: `Access to all PREMIUM designs`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.ACCESS_PREMIUM_PROJECT,
		},
		{
			description: `${USAGE_LIMIT.DEFAULT_DOWNLOAD.ELITE === null ? 'Unlimited' : USAGE_LIMIT.DEFAULT_DOWNLOAD.ELITE} default-quality downloads`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_DEFAULT_DOWNLOAD,
			hiddenByDefault: true,
		},
		{
			description: `${USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.ELITE === null ? 'Unlimited' : USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.ELITE} High-quality images`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_HQ_IMAGE_DOWNLOAD,
		},
		{
			description: `${
				USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.ELITE === null ? 'Unlimited' : USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.ELITE
			} High-quality videos per month`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_HQ_VIDEO_DOWNLOAD,
		},
		{
			description: `${USAGE_LIMIT.AI_USAGE.ELITE === null ? 'Unlimited' : USAGE_LIMIT.AI_USAGE.ELITE} AI generations per month`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.AI_STYLIST_EXCEED_LIMIT,
			hiddenByDefault: true,
		},
		{
			description: `Exclusive presets & materials`,
			featureType: null,
		},
		{
			description: `Exclusive character poses`,
			featureType: null,
		},
		{
			description: `Commercial license`,
			featureType: null,
		},
		{
			description: `3D file exports`,
			featureType: UPGRADE_PLAN_TRIGGER_TYPE.GLB_DOWNLOAD,
		},
	],
};
